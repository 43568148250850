import React from "react";
import { useState } from 'react';
import Presentation from "../components/Presentation";
import Timeline from "../components/Timeline";
import classes from '../sass/pages/Home.module.scss';
import { Link } from 'react-router-dom';

const Home = () => {
	const [displayTimeline, setDisplayTimeline] = useState(false);
	const [ displayFooter, setDisplayFooter ] = useState(true);


	return (
		<div className={classes.container}>
			<Presentation displayTimeline={displayTimeline} setDisplayTimeline={setDisplayTimeline}/>

			{ displayTimeline && <Timeline displayFooter={displayFooter} setDisplayFooter={setDisplayFooter}/> }
			{/* <Timeline /> */}
			

			{
				displayFooter &&
				<footer>
					<Link className={classes.link} to="/contact">Contact</Link>
				</footer>
			}

			
		</div>
		
	);
}

export default Home;