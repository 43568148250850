import classes from '../sass/components/MajorEvent.module.scss';
import Typewriter from './Typewriter';
import { useState, useEffect } from 'react';

const MajorEvent = ({ event, isSelected, onClick }) => {
  const options = { year: 'numeric', month: 'short' };
  const [isTypewriterVisible, setIsTypewriterVisible] = useState(false);

	const startTypewriterAnimation = () => {
		const timer = setTimeout(() => {
			setIsTypewriterVisible(true);
		}, 3000);
		return () => clearTimeout(timer);
	};

	useEffect(() => {
	if (isSelected) {
		startTypewriterAnimation();
	}
	else {
		setIsTypewriterVisible(false);
	}
	}, [isSelected]);

	const parseDescription = (description) => {
		return description.split('\n').flatMap((line, i) => {
			return line.match(/(\*\*(.*?)\*\*)|([^*]+)/g).map((segment, j) => {
				if (segment.startsWith('**') && segment.endsWith('**')) {
					return <span key={`${i}-${j}`} className="colored">{segment.slice(2, -2)}</span>;
				} else {
					return segment;
				}
			}).concat(<br key={`${i}-br`} />);
		});
	};

  return (
	<div 
		className={`${classes.event} ${isSelected ? classes.expanded : ''}`}
	>
      <div className={`${event.type === 'academic' ? classes.academic : classes.professional}`}>
        	{ !isSelected && 
				<div className={classes.verticalLine} /> 
			}
			<div className={classes.date}>
				{event.startDate.toLocaleDateString(undefined, options)}
				{
					isTypewriterVisible &&
					<div className={classes.location}>
						<Typewriter text={event.location} delay={50} />
					</div>
				}
			</div>
       		<div className={classes.title} onClick={onClick}>
				{event.title}
				{/* <button className={classes.button}> */}
				{
					!isSelected &&
					<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24">
					<defs>
					  <filter id="neon">
						<feDropShadow dx="0" dy="0" stdDeviation="5" floodColor="#abd699"/>
					  </filter>
					  <filter id="big-neon">
						<feDropShadow dx="0" dy="0" stdDeviation="45" floodColor="#abd699"/>
					  </filter>
					</defs>
					<path filter="url(#neon)" d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z" fill="#abd699"/>
				  </svg>
				}
			</div>
			{ isSelected &&
				<div className={classes.eventInfo}>
					<div className={classes.description}>
						{parseDescription(event.description)}
						<div className={classes.more}>
							{ 
								event.skills && event.skills.length > 0 &&
								<div className={classes.skills}>
									✍️ What I've learnt
									<ul>
										{ 
											event.skills.map((skill, index) => (
												<li key={index}>
													{skill}
												</li>
											))
										}
									</ul>
								</div>
							}
							{ 
								event.links && event.links.length > 0 &&
								<div className={classes.links}>
									📖 Learn more
									<ul>
										{
											event.links.map((link, index) => (
												<li key={index}>
													<a href={link.url} target="_blank" rel="noopener noreferrer">
														{link.text}
													</a>
												</li>
											))
										}
									</ul>
								</div>
							}
						</div>
					</div>
					<div className={classes.pictures}>
						{
							event.pictures && event.pictures.length &&
								event.pictures.map((picture, index) => (
							<img key={index} src={picture} alt={event.title} />
							))
						}
					</div>
				</div>
			}
      </div>
    </div>
  );
}

export default MajorEvent;