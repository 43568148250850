import React from "react";
import { useState, useEffect, useMemo } from 'react';
import classes from '../sass/components/Presentation.module.scss';

const Presentation = ({ displayTimeline, setDisplayTimeline }) => {

	const adjectives = useMemo(() => ['skilled in C/C++', 'curious', 'always learning', 'seeking for my next opportunity'], []);
	const [text, setText] = useState("");
	const [currentAdjective, setCurrentAdjective] = useState(0);
	const [charIndex, setCharIndex] = useState(0);
	const [waiting, setWaiting] = useState(false);

	useEffect(() => {
		if (waiting)
			return;
		
		if (charIndex < adjectives[currentAdjective].length) {
			const timer = setTimeout(() => {
				setText((prevText) => prevText + adjectives[currentAdjective][charIndex]);
				setCharIndex((prevCharIndex) => prevCharIndex + 1);
			}, 50);
			return () => clearTimeout(timer);
		}
		else
			setWaiting(true);
	}, [text, charIndex, waiting, adjectives, currentAdjective]);

	useEffect(() => {
		if (!waiting)
			return;
		
		const waitTimer = setTimeout(() => {
			setCharIndex(0);
			setCurrentAdjective((prevAdjective) => {
				return (prevAdjective < adjectives.length - 1) ? prevAdjective + 1 : prevAdjective;
			});

			if (currentAdjective < adjectives.length - 1) {
				setText('');
				setWaiting(false);
			}
			else
				setDisplayTimeline(true);

		}, 1000);
		return () => clearTimeout(waitTimer);

	}, [charIndex, waiting, currentAdjective, adjectives, setDisplayTimeline]);

  return (
	<div className={` ${displayTimeline ? classes.active : ''}`}>
		<div className={classes.container}>
		I am
		<div className={classes.title}> {text}</div>
		</div>
	</div>
  );
}

export default Presentation;
