import classes from '../sass/components/Timeline.module.scss';
import MajorEvent from './MajorEvent';
import { useState } from 'react';
import stp_fcj2 from '../assets/fcj-laptops2.jpg';
import stp_fcj3 from '../assets/fcj-laptops3.jpg';
import dhbw from '../assets/dhbw.jpeg';
import epitech from '../assets/epitech.jpg';
import dauphine from '../assets/dauphine.jpg';
import transcendence_demo from '../assets/transcendence_demo.gif';
import cub3d_demo from '../assets/cub3d_demo.gif';
import piscine42 from '../assets/piscine42.jpg';

const Timeline = ({ displayFooter, setDisplayFooter }) => {
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [isEventSelected, setIsEventSelected] = useState(false);

	let events = [
        { 
			startDate: new Date(2017, 8), 
			endDate: new Date(2018, 11), 
			type: 'academic', 
			title: "Bachelor's Degree in International Business", 
			description: "In 2017, I completed my scientific 'baccalaureat' - French A-level equivalent - with high honours. I was part of the European class teaching philosophy in German and thus I decided to pursue my studies within the Double Degree in International Business from **Paris Dauphine** and its German partner university **DHBW Mannheim**.\nThis 3-year program, which was fully taught in English and based on an apprenticeship model, alternated between 3 months at the university and 3 months working for a company. The lectures for the first 1.5 years took place in Mannheim, Germany, and then in Paris.",
			skills: ['Macroeconomics', 'Finance, Monetary Analysis', 'Intercultural management', 'International Marketing'],
			location: 'Mannheim, Germany',
			links: [
				{
					text: 'About Dauphine', 
					url:'https://dauphine.psl.eu/international/partir-a-letranger/en-licence/double-diplome-franco-allemand' },
				{
					text:'About DHBW', 
					url: 'https://www.mannheim.dhbw.de/en/dual-study/bachelors-degree-courses/business/business-administration/international-business' }
			],
			pictures: [ dhbw, dauphine ] },
        { 
			startDate: new Date(2017, 11), 
			endDate: new Date(2018, 2), 
			type: 'professional', 
			title: 'Deutsche Bank Internship',
			description: "For the first practical phase of my Bachelors's Degree, I have done an internship at the **Deutsche Bank** withing the Global Reconciliation Department in Luxembourg.\nAt this period, the bank was in the challenging phase of migrating its banking software. That's when I met the new banking software's consultant who shared with the challenging insights about **software migration**. I observed firsthand the degree to which the company relied on its IT systems, planting the first seed in my mind about the significance of Information Technology.", 
			location: 'Luxembourg City, Luxembourg',
			skills: ['Auditing', 'Bank reconciliation'],
		},
        { 
			startDate: new Date(2018, 5), 
			endDate: new Date(2020, 8), 
			type: 'professional', 
			title: 'Freudenberg Business Developer', 
			description: "Freudenberg Filtration Technologies hired me as an apprentice within the 3-years program that I was enrolled in. **Freudenberg** is a German Industrial Group and I've worked for the Filtration Technologies part.\nI worked both at the German headquarters learning about the main sales, logistics and purchasing processes and then at the French subsidiary in Paris.\nI have worked closely with the Directing Manager of the French subsidiary developing tools to support the business. One of my project consisted in finding a solution to support a core sales task: prospecting. Therefore I developed a **Lead Management Tool** to best target potential clients using data-mining tools. With the help of a developer, I built the back-end solution in **Python** and the front-end was incorporated in one of the corporate tool: SharePoint.\nThis experience was very enriching and again emphasized the importance of IT solutions for a business. Altough I noticed one of its biggest constraint: people and **change management**.",
			location: 'Weinheim, Germany',
			skills: ['Microsoft tools', 'Python', 'Change management'],
			links: [
				{
					text: 'About Freudenberg',
					url: 'https://www.freudenberg.com/#'}, 
				{
					text: 'About Freudenberg Filtration',
					url: 'https://www.freudenberg-filter.com/en/' }
			]
		},
        { 
			startDate: new Date(2020, 9), 
			endDate: new Date(2021, 8), 
			type: 'academic', 
			title: "Bachelor's Degree in Computer Science", 
			description: "In 2020, I've decided to build my independence over what I would forever be surrouned with: IT.\nThus after successfully completing my Bachelor's Degree in International Business, I've decided learning **Computer Science** at **Epitech**. I was enrolled in the Pre-Msc program, preparing students from different backgrounds for Epitech's Masters's Degree program. This program was in apprenticeship and thus allowed me to work in a different department at Freudenberg (learn more in the next section). It was a challenging year in a completely new field, where I was surrouned by my fellow classmates who previously studied or worked in IT.\nThe program covered multiple sides of IT such as: Web and Mobile Development, System Administration, Security, Software Programming.", 
			location: 'Strasbourg, France',
			skills: ['Javascript', 'Java', 'VueJs', 'Docker'],
			links: [ { text: 'About Epitech and Pre Msc', url: 'https://www.epitech.eu/fr/formations/pre-msc/'} ],
			pictures: [ epitech ]
		},
        { 
			startDate: new Date(2021, 1), 
			endDate: new Date(2021, 7), 
			type: 'professional', 
			title: 'Freudenberg RPA developer', 
			description: "My second apprenticeship at Freudenberg was among the Corportate Processes and Information Management Department at the German headquarters. I worked with the CIO and the Global Manager of Lean Business Processes.\nAs part of my role, I worked both on the business and technical side of **process automation**. I had to analyse and identify optimal processes to automate within the company based on factors such as labor utilisation and development feasibility. And I developed these 'robots' to automate processes. In half a year, I have implemented three different robots across India, China and Germany using **UiPath** tool.", 
			location: 'Weinheim, Germany',
			links: [ 
				{ text: 'About Freudenberg', url: 'https://www.freudenberg.com/#'}, 
				{ text: 'About UiPath', url: 'https://www.uipath.com/'} ],
			skills: [ 'UiPath', 'Automation', 'Lean Business Processes']
		},
        { 
			startDate: new Date(2021, 10), 
			endDate: new Date(2022, 1), 
			type: 'professional', 
			title: 'São Tomé e Príncipe Volunteering', 
			description: "In 2021, after completing Epitech's Bachelor Degree, I decided to volunteer for an organization in **São Tomé e Príncipe in Africa**.\nMy experience at Epitech with its **'peer-to-peer'** pedagogy - learning without teachers, by completing projects, searching on internet and sharing with other students - made me learn very efficiently and I felt the need to further spread that pedagogy and the powerful tool of **Internet** and IT at the Fundação da Criança e da Juventude in São Tomé, Africa. Thanks to Epitech generous **laptop donation**, I managed to bring these to Africa and teach kids of 8 to 14 years old about **web searching techniques** and basic IT skills.", 
			location: 'São Tomé, São Tomé e Príncipe, Africa',
			skills: [ '👩‍🏫 🤗 🫶' ],
			links: [
				{ text: 'About Fundação da Criança e da Juventude', url: 'https://fundacaocriancajuv.wixsite.com/fcjsaotome'}, 
				{ text: 'Where is São Tomé e Príncipe', url: 'https://earth.google.com/web/@0.89853958,6.99357846,19.08326149a,297805.88414006d,35y,0.00022h,0t,0r/data=ChAaDgoIL20vMGo2ODEYAiAB'} ],
			pictures: [ stp_fcj2, stp_fcj3 ]
		},
        { 
			startDate: new Date(2022, 2), 
			endDate: new Date(2022, 3), 
			type: 'academic', 
			title: "42 Paris Piscine", 
			description: "Coming back from Africa, I participated in 42 Paris famous selection '**piscine**', which consists of a one month intensive **C programming** selection month. With a similar peer-to-peer pedagogy as Epitech, 42 selects its student based on the outcome of their piscine: programming skills, learning curve, collectivism and mindset. Everyday completing new exercices in C and getting corrected by other 'pisciners' and a robot - moulinette.",  
			location: 'Paris, France',
			links: [ { text: 'La Piscine', url: 'https://42.fr/admissions/42-piscine/'},
					{ text: 'C subjects', url: 'https://github.com/olivwalsh/piscine_march22'}
			],
			skills: [ 'C programming', 'Bash', 'Interpersonal Skills' ],
			pictures: [ piscine42 ]
		},
        { 
			startDate: new Date(2022, 4), 
			endDate: new Date(2023, 6), 
			type: 'academic', 
			title: '42 Paris Journey', 
			description: 'After the piscine, I was selected to further learn about programming at 42. Since May 2022, I have been immersed in learning about C and C++ in a highly diverse and collaborative environment. Some of the main projects I have completed include a Wolfenstein-like **3D Raycasting** project (cub3D) in C, redeveloping the famous C++ containers (vector, stack, map, set) using a **Red Black Tree** data structure for the map container, and the development of an **Internet Relay Chat**. The final project of the 42 Common Core was a complete Pong game web application, including a chat feature, which I developed with three other students using **React**, **NestJS**, and **Typescript**.',
			skills: [ 'C / C++', 'Docker', 'React', 'NestJS', 'Typescript' ],
			links: [
				{
					text: 'My cub3D', url: 'https://github.com/olivwalsh/cub3d'
				},
				{
					text: 'ft_containers', url: 'https://github.com/olivwalsh/ft_containers'
				},
				{
					text: 'Pong Application', url: 'https://github.com/bCigueS/ft_transcendence'
				}
			],
			location: 'Paris, France',
			pictures: [ transcendence_demo, cub3d_demo ]
		},
    ];

	const handleBackToTimeline = () => {
		if (isEventSelected) {
			setDisplayFooter(true);
		  	window.history.back();
		}
	}

	const handleBrowserBackButton = () => {
		setIsEventSelected(false);
		setSelectedEvent(null);
		setDisplayFooter(true);
		window.removeEventListener('popstate', handleBrowserBackButton);
		// console.log('window back button has been selected');
	}

	const handleSelectEvent = (index) => {
			if (!isEventSelected) {
				setDisplayFooter(false);
				setIsEventSelected(true);
				setSelectedEvent(index);
				window.history.pushState({ eventIndex: index }, '');
				window.addEventListener('popstate', handleBrowserBackButton);
		}
	  }

	return (
			<div className={`${classes.timeline} ${isEventSelected ? classes['event-selected'] : ''}`}>
				<div className={classes.events}>
					{events.map((event, index) => {
						if (selectedEvent !== null && selectedEvent !== index) {
							// console.log('returning null, with selectedEvent: ', selectedEvent, ' and index: ', index);
							return null;
						}
						return (
							<MajorEvent 
							key={index} 
							event={event} 
							isSelected={selectedEvent === index} 
							onClick={() => {
								setSelectedEvent(selectedEvent === index ? null : index)
								setIsEventSelected(!isEventSelected);
								handleSelectEvent(index);
							}}
							/>
						);
					})} 
					
				{isEventSelected && 
					<div className={classes.backArrow} onClick={handleBackToTimeline}>
						<svg height="2rem" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
							<defs>
							<filter id="neon">
								<feDropShadow dx="0" dy="0" stdDeviation="5" floodColor="#abd699"/>
							</filter>
							<filter id="big-neon">
								<feDropShadow dx="0" dy="0" stdDeviation="45" floodColor="#abd699"/>
							</filter>
							</defs>
							<polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "  fill="#abd699"/>
						</svg>
					</div>
				}
				</div>
			</div>
		
	);
}

export default Timeline;