import React from 'react';
import classes from '../sass/components/CodeFragment.module.scss';

const CodeFragment = ({ object }) => {

	return (
		<div className={classes.codeFragment}>
			<span className={classes.boring}>const</span> 
			<span className={classes.title}>{' ' + object.title}</span> 
			<span className={classes.boring}>{' = '}</span>
			<span className={classes.special}>{" {"}</span>
			<ul>
				{ 
					object.values.map((value, index) => (
						<li key={index}>
							<span className={classes.value}>{value.name}</span>: {value.value}
							{
								index !== object.values.length - 1 &&
								<span className={classes.boring}>,</span>
							}
						</li>
					))
				}
			</ul>
			<span className={classes.special}>{"}"}</span>
		</div>
	)
}

export default CodeFragment;