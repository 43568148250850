import React from "react";
import classes from '../sass/pages/Contact.module.scss';
import cv from '../assets/cv.png';
import CodeFragment from '../components/CodeFragment.jsx';
import Typewriter from "../components/Typewriter";
import { Link } from 'react-router-dom';

const Contact = () => {

	const aboutCode = {
		title: 'about',
		values: [
			{
				name: 'firstName',
				value: 'Olivia'
			},
			{
				name: 'lastName',
				value: 'Walsh'
			},
			{
				name: 'age',
				value: '23'
			},
		]
	}

	const internship = {
		title: 'seekingFor',
		values: [
			{
				name: 'title',
				value: 'internship'
			},
			{
				name: 'location',
				value: 'Paris area'
			},
			{
				name: 'starting',
				value: 'Sep 2023'
			},
			{
				name: 'duration',
				value: '4-6 months'
			},
			{
				name: 'field',
				value: 'software development'
			},
		]
	}

	return (
		<div >
			<div className={classes.major}>
				<img src={cv} alt=""/>
				<h1>👋 I'm <span><Typewriter text="Olivia" delay="100"/></span></h1>

			</div>
			<div className={classes.container}>

			<div className={classes.left}>
				{/* <div className={classes.skills}>
					<h2>Skills</h2>
					<div className={classes.skillCategory}>
						<span>Programming Languages</span>
						<ul>
						{
							programmingLanguages.map((programmingLanguage, index) => (
								<li key={index}>
									{programmingLanguage}
								</li>
							))
						}
						</ul>
					</div>
					<div className={classes.skillCategory}>
						<span>Web Development</span>
						<ul>
						{
							webSkills.map((webSkill, index) => (
								<li key={index}>
									{webSkill}
								</li>
							))
						}
						</ul>
					</div>
					<div className={classes.skillCategory}>
						<span>Devops</span>
						<ul>
						{
							devops.map((devop, index) => (
								<li key={index}>
									{devop}
								</li>
							))
						}
						</ul>
					</div>
					<div className={classes.skillCategory}>
						<span>Languages</span>
						<ul>
						{
							languages.map((language, index) => (
								<li key={index}>
									{language}
								</li>
							))
						}
						</ul>
					</div>
				</div> */}
			</div>
			<div className={classes.right}>
				<div className={classes.codeFragment}>
					<div className={classes.topLeft}>
						<CodeFragment key={aboutCode.title}  object={aboutCode}/>
					</div>
					<div className={classes.bottomRight}>
						<CodeFragment key={internship.title} object={internship}/>	
					</div>
				</div>
				<div className={classes.contact}>
					<a href="https://www.linkedin.com/in/oliviaclarisse-walsh/" target="_blank" rel="noopener noreferrer">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#c7ddcc" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
						<span>
							/oliviaclarisse-walsh
						</span>
					</a>
					<a href="https://github.com/olivwalsh" target="_blank" rel="noopener noreferrer">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#c7ddcc" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/></svg>
						<span>
							/olivwalsh
						</span>
					</a>

					<a href = "mailto:owalsh@student.42.fr?subject=I want to hire you!">
						<svg xmlns="http://www.w3.org/2000/svg"   fill="#c7ddcc" width="24" height="24" viewBox="0 0 24 24"><path d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z"/></svg>
						<span>
							owalsh@student.42.fr
						</span>
					</a>

				</div>
			</div>

			</div>
			<footer>
				<Link className={classes.link} to="/">
					What I have done so far
				</Link>
			</footer>
		</div>
	)
}

export default Contact;